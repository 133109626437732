import { useState, useMemo, useEffect } from 'react'
import { InventoryItemSearchCriteria, InventoryItem } from '../../@types/inventory'
import { useGetInventoryItemsQuery } from '../../services/inventory'
import { TextField, Typography, Stack } from '@material-ui/core';
import { fCurrency } from '../../utils/formatNumber';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import debounce from 'lodash/debounce';

type InventoryItemSelectListProps = {
    onChange(option: InventoryItemOption | null): void;
    excludedIds: string[];
    label?: string;
    disabled?: boolean;
    clearItemOnSelect?: boolean;
    initialValue?: InventoryItemOption | null;
};

type InventoryItemOption = {
    id?: string;
    category?: string;
    title: string;
    description?: string;
    unitPrice?: number | null;
    imageUrl?: string;
    inputValue?: string;
}

const filter = createFilterOptions<InventoryItemOption>({
    stringify: (option) => `${option.title} ${option.description}`,
});

export default function InventoryItemSelectList({ onChange, label, excludedIds, disabled = false, clearItemOnSelect = true, initialValue = null }: InventoryItemSelectListProps) {
    
    const [selectedItem, setSelectedItem] = useState('');
    const [value, setValue] = useState<InventoryItemOption | null>(initialValue);
    const debouncedChangeHandler = useMemo(() => debounce((userInput) => handleChangeSearch(userInput), 100), []);
    const [search, setSearch] = useState<InventoryItemSearchCriteria>({
        pageNumber: 0,
        pageSize: 20,
        orderBy: 'description',
        order: 'desc',
        text: '',
    })

    const defaultData: { results: InventoryItem[] } = { results: [] }
    const { data = defaultData, isLoading, isFetching } = useGetInventoryItemsQuery(search);

    const handleChangeSearch = async (value: string) => {
        setSearch({
            ...search,
            text: value
        });
    };

    useEffect(() => {
        onChange && onChange(value);
    }, [value])

    return (
        <Autocomplete
            value={value}
            fullWidth
            selectOnFocus
            disabled={disabled}
            defaultValue={initialValue}
            clearOnBlur
            handleHomeEndKeys
            inputValue={selectedItem}
            filterSelectedOptions
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
            }}
            loading={isLoading || isFetching}
            multiple={false}
            options={data.results.map((item: InventoryItem) => {
                var option: InventoryItemOption = {
                    id: item.id,
                    title: item.code,
                    description: item.description,
                    unitPrice: item.salesDetails?.unitPrice,
                };
                return option;
            })}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="column">
                                <Typography variant="subtitle1">{option.description}{option.unitPrice ? ` (${fCurrency(option.unitPrice )})` : ''}</Typography>
                                <Typography variant="body2">{option.title}</Typography>
                            </Stack>
                        </Stack>
                    </li>
                )
            }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            onInputChange={(event, value, reason) => {
                // clear the item if required
                if (clearItemOnSelect) {
                    if (event && (event.type === 'blur' || event.type === 'click')) {
                        setSelectedItem('');
                        debouncedChangeHandler('');
                    } else if (reason !== 'reset') {
                        // reset looks to be triggered on option select
                        setSelectedItem(value);
                        debouncedChangeHandler(value);
                    }
                } else {
                    setSelectedItem(value);
                    debouncedChangeHandler(value);
                }
            }}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        //id: null,
                        title: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                        //id: null,
                        title: newValue.inputValue,
                    });

                    // clear the item after creating it if required
                    if (clearItemOnSelect) {
                        setSelectedItem('');
                    }
                } else {
                    setValue(newValue);
                }
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                />
        }
    />
    );
}