
export type Checksheet = {
    checklist: Checklist;
    dimensions: Dimension[];
}

export type Checklist = {
    checkListId: string;
    name: string;
    editable: boolean;
    isComplete: boolean;
    items: ChecklistItem[];
    groups: ChecklistGroup[];
    //customFields?: ChecklistCustomField[]
}

export type ChecklistCustomField = {
    name: string;
    dimensions: Dimension[];
}

export type Dimension = {
    id: string;
    name: string;
    description?: string;
    enabled: boolean;
    measurements: Measurement[];
}

export type Measurement = {
    name: string;
    value1: number | null;
    value2?: number | null;
}

export type ChecklistGroup = {
    index: number;
    groupId: string;
    title: string;
    items: ChecklistItem[];
}

export type ChecklistItem = {
    itemId: string;
    status: ChecklistItemStatus
    title: string;
    description: string;
    group?: string;
    groupId: string;
    index?: number;
}

export type ChecklistGroupCreate = {
    checklistId: string;
    title: string;
}

export type ChecklistItemsReorderRequest = {
    groups: {
        group: string;
        items: ChecklistItem[];
    }[]
}

export type ChecklistItemUpdateRequest = {
    checkListId: string;
    itemId: string;
    index?: number;
    title?: string;
    description?: string;
    group?: string | null;
    status: ChecklistItemStatus
}

export type ChecklistItemCreateRequest = {
    title: string;
    description?: string;
    group?: string;
    status: ChecklistItemStatus;
}

export enum ChecklistItemStatus {
    Default = "Default",
    Complete = "Complete",
    Adjust = "Adjust",
    Replace = "Replace",
    NotApplicable = "NotApplicable",
}

export type Item = {
    id: string;
    primary: string;
    secondary: string;
};