import { Entity } from './entity'
import { User } from './user'
import { ISearchCriteria } from './search'
import { SynchronisationState } from './tracking'

export interface Invoice {
    id: string;
    reference: string;
    invoiceNumber: string;
    description: string;
    amountDue: number;
    amountPaid: number;
    subTotal: number;
    total: number;
    date: Date | null;
    dueDate: Date | null;
    entity: Entity;
    status: InvoiceStatus;
    type: InvoiceType;
    lineItems: InvoiceLineItem[];
    webUrl: string;
    externalIdentifier: string | null;
    association?: InvoiceAssociation | null;
    invoiceLink: InvoiceLink | null;
    synchronisationState: SynchronisationState | null;
}

export interface InvoiceLineItem {
    lineItemId: string;
    inventoryItemId?: string | null;
    code: string;
    description: string;
    lineAmount: number | null;
    unitAmount: number | null;
    quantity: number;
    whenUpdated: Date;
}

export interface InvoiceAssociation {
    resourceId: string;
    type: InvoiceAssociationType;
    updated: string;
}

export enum InvoiceAssociationType {
    Job = "Job",
}

export interface InvoiceLink {
    updated: string;
    linkedResourceId: string;
    linkType: InvoiceLinkType;
    whoCreatedUser: User;
}

export enum InvoiceLinkType {
    Vehicle = "Vehicle",
}

export enum InvoiceStatus {
    Draft = "Draft",
    Pending = "Pending",
    Submitted = "Submitted",
    Authorised = "Authorised",
    Paid = "Paid",
    Voided = "Voided",
    Deleted = "Deleted",
}

export enum InvoiceType {
    AccountsPayable = "AccountsPayable",
    AccountsReceivable = "AccountsReceivable",
}

export type InvoiceRequest = {
    invoiceDate?: Date;
    reference?: string | null;
    description?: string | null;
}

export type InvoiceCreateRequest = InvoiceRequest &{
    customerId: string;
}

export type InvoiceUpdateRequest = InvoiceRequest & {
    invoiceId: string;
    status: InvoiceStatus;
}

export type InvoiceLineItemRequest = {
    invoiceId: string;
    inventoryItemId?: string | null;
    code: string;
    description: string;
    lineAmount: number;
    quantity: number;
}

export type InvoiceLineItemUpdateRequest = {
    invoiceId: string;
    lineItemId: string;
    inventoryItemId?: string | null;
    code: string | null;
    description: string;
    quantity: number;
    //lineAmount: number | null;
    unitAmount: number | null;
}

export interface InvoiceSearchCriteria extends ISearchCriteria {
    unlinkedOnly: boolean;
    statusFilter: InvoiceStatus[];
    typeFilter: InvoiceType[];
}

export interface InvoiceSearchSearchResult {
    invoices: Invoice[];
    totalCount: number;
}

export type InvoiceTrendStatistics = {
    statusList: InvoiceStatus[];
    
}