import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

let getAccessTokenFunction: (options: any) => Promise<string>;

export function setAccessTokenRetriever2(getAccessToken: any) {
  getAccessTokenFunction = getAccessToken;
}

const staggeredBaseQuery = retry(fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_GATEWAY}/api`,
  prepareHeaders: async (headers) => {
    const token = await getAccessTokenFunction({ ignoreCache: false });
    headers.set('Authorization', `Bearer ${token}`);
    headers.set('Accept', 'application/json');
    return headers;
  },
}), {
  retryCondition: (err, args, extraArgs) => (err.status !== 400 && err.status !== 403 && err.status !== 404 && err.status !== 500) && extraArgs.attempt < 3,
})

export const api = createApi({
  tagTypes: [
    'User',
    'Vehicle',
    'Invoice',
    'Compliance',
    'ComplianceConfig',
    'WorkOrder',
    'WorkOrderItem',
    'Service',
    'ServiceTask',
    'ServiceItem',
    'ServicePlan',
    'ServicePlanItem',
    'ServicePlanTask',
    'ServicePlanVehicle',
    'InventoryItem',
    'Clients',
    'Job',
    'Project',
    'ServiceType',
    'JobItem',
    'SubscriptionPlan',
    'Group',
    'Integration'],
  baseQuery: staggeredBaseQuery,
  refetchOnFocus: false,
  endpoints: (builder) => ({

  }),
})

export const { } = api;